/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply p-0 m-0 text-gray-700;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@layer components {
  .create-new-component-title-text {
    @apply flex text-base font-semibold;
  }
  .text-input-container {
    @apply group flex items-center mx-auto w-full border border-gray-300 bg-white rounded px-3 py-2 focus-within:border-cmGreen-300;
  }
  .btn {
    @apply flex justify-center w-full mt-4 py-1 border rounded-lg text-white bg-cmGreen-400 hover:opacity-80 focus:outline-none active:opacity-100 focus:ring-2 ring-cmGreen-400;
  }
}
